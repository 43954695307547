import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SignoutpopupComponent } from 'src/app/rvcloud/signoutpopup/signoutpopup.component';
import { AuthService } from 'src/app/services/auth.service';
import { TrainerydataService } from 'src/app/services/trainerydata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reviewbuilderadmin',
  templateUrl: './reviewbuilderadmin.component.html',
  styleUrls: ['./reviewbuilderadmin.component.css']
})
export class ReviewbuilderadminComponent implements OnInit {

  opened = false;
  isInitial = true;
  router: Router;
  authUser = null;
  expandFlag=false;
  divStyle=47;
  isExandedVisible=false;
  myapps:any;
  trainery_url=environment.trainery_url;
  user_Data:any;
    organisationImage:string=''
  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private traineryData:TrainerydataService
  ) {
     this.router = _router;
    let traineryView=localStorage.getItem('traineryView')

    if(traineryView=='false')
    {
      let apps:any = this.authService.setTraineryTempData();
      this.organisationImage=this.traineryData.getTraineryApplicationData('organisation')?.logo_url
      
      this.myapps = apps;
      this.user_Data = this.traineryData.getTraineryApplicationData('user_data')

      
    }
else
{
  this.isExandedVisible = this.authService.getCookie('pinned') == 'True'?true: false;
  this.onExpand();
  this.organisationImage=this.traineryData.getTraineryApplicationData('organisation')?.logo_url


  this.myapps =   this.traineryData.getTraineryApplicationData('applications')
  this.user_Data = this.traineryData.getTraineryApplicationData('user_data')

}
    
 
  }

  public isActiveUrl(base: string): boolean {
    return this.router.url.includes(`/${base}`);
 }

  ngOnInit(): void {
    localStorage.setItem('tab','standard');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

  }
  onStatusChange(){
    this.expandFlag=!this.expandFlag;
  }
  toggleSidebar(): void {
    this.opened = !this.opened;
    this.isInitial = false;
  }
  

  SignoutPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass='signout-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };

    this.dialog.open(SignoutpopupComponent, dialogConfig);
  }

 
  onExpand(){
    
    if(this.isExandedVisible == true){
      this.divStyle = 200;
    }
    else{
      this.divStyle = 47;
    }
  }
  logoutExit(){
    this.authService.logout();
  }

  onActivate(event) {
    window.scroll(0,0);
  }

}

