import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardGuard implements CanActivateChild {

  userType:string='';
  constructor(private authservice:AuthService,
    private route :Router
  ){
    this.userType=this.authservice.getRole();


  }



  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(this.userType!='hr')
      {
        this.route.navigate(['access-denied'])
      }

    return true;
  }
  
}
