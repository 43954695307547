import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'reviewcloudrc';
  router: Router;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 8;
  environment=environment;
  traineryUrl=environment.trainery_url
  production_trainery=environment.production_trainery
  // initiate it in your component OnInit
  constructor(private bnIdle: BnNgIdleService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialog) {
      this.router = _router;
    this.bnIdle.startWatching(1000).subscribe((res) => {
      if(res) {
          this.dialogRef.closeAll();
          this.openSnackBar() 
            this.router.navigate(['/login/rc']);

        }
    })
  }
  openSnackBar() {
    this._snackBar.open('session expired!!', 'x', {
      horizontalPosition: this.horizontalPosition, 
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000
    });
  
      this.router.navigate(['/login/rc']);

    window.location.reload();
  }
  onActivate(event) { 
    window.scroll({ 
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
     });

 }
}
