<div>
  <div className="top-menu-container">
    <div className="logo_container">
    </div>
  </div>
  <div [ngClass]="isOpen  ? 'leftmenu-open' : 'leftmenu-closed'"  id="leftmenu">
    <div class="top-section">
      <div [ngClass]="isOpen  ? 'top-section-bars' : 'top-section-close'">
        <ng-container *ngIf="isOpen">
          <ng-container *ngIf="!pin">
            <span (click)="pinToggle()" role="img" aria-label="pushpin" tabindex="-1" class="anticon anticon-pushpin reg_icon-top"><svg viewBox="64 64 896 896" focusable="false" data-icon="pushpin" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3a33.23 33.23 0 00-2.7 49.4l181.7 181.7-215.4 215.2a15.8 15.8 0 00-4.6 9.8l-3.4 37.2c-.9 9.4 6.6 17.4 15.9 17.4.5 0 1 0 1.5-.1l37.2-3.4c3.7-.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8zM666.2 549.3l-24.5 24.5 3.8 34.4a259.92 259.92 0 01-30.4 153.9L262 408.8c12.9-7.1 26.3-13.1 40.3-17.9 27.2-9.4 55.7-14.1 84.7-14.1 9.6 0 19.3.5 28.9 1.6l34.4 3.8 24.5-24.5L608.5 224 800 415.5 666.2 549.3z"></path></svg></span>
            <span  (click)="toggle()" role="img" aria-label="double-left" tabindex="-1" class="anticon anticon-double-left reg_icon-top"><svg viewBox="64 64 896 896" focusable="false" data-icon="double-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M272.9 512l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L186.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H532c6.7 0 10.4-7.7 6.3-12.9L272.9 512zm304 0l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L490.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H836c6.7 0 10.4-7.7 6.3-12.9L576.9 512z"></path></svg></span>
           
          </ng-container>
          <ng-container *ngIf="pin">
            <span (click)="pinToggle()" role="img" aria-label="pushpin" tabindex="-1" class="anticon anticon-pushpin reg_icon-top"><svg viewBox="64 64 896 896" focusable="false" data-icon="pushpin" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M878.3 392.1L631.9 145.7c-6.5-6.5-15-9.7-23.5-9.7s-17 3.2-23.5 9.7L423.8 306.9c-12.2-1.4-24.5-2-36.8-2-73.2 0-146.4 24.1-206.5 72.3-15.4 12.3-16.6 35.4-2.7 49.4l181.7 181.7-215.4 215.2a15.8 15.8 0 00-4.6 9.8l-3.4 37.2c-.9 9.4 6.6 17.4 15.9 17.4.5 0 1 0 1.5-.1l37.2-3.4c3.7-.3 7.2-2 9.8-4.6l215.4-215.4 181.7 181.7c6.5 6.5 15 9.7 23.5 9.7 9.7 0 19.3-4.2 25.9-12.4 56.3-70.3 79.7-158.3 70.2-243.4l161.1-161.1c12.9-12.8 12.9-33.8 0-46.8z"></path></svg></span>
            <span (click)="toggle()" role="img" aria-label="double-left" tabindex="-1" class="anticon anticon-double-left reg_icon-top"><svg viewBox="64 64 896 896" focusable="false" data-icon="double-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M272.9 512l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L186.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H532c6.7 0 10.4-7.7 6.3-12.9L272.9 512zm304 0l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L490.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H836c6.7 0 10.4-7.7 6.3-12.9L576.9 512z"></path></svg></span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isOpen">
          <img class="top-section-icon-styles-2" src="../../../assets/img/side-menu/ArrowOpen.png" alt="name" (click)="toggle()" />

        
        </ng-container>
      </div>
    </div>
    <ng-container *ngFor="let app of applications;let i = index">
      <div>
        <ng-container *ngIf="app.drop_down">
         
          <a href="#" class={{generateClassName(app.name,app.showDropdown)}} (click)="toggleDropdown($event, i,app)">
            <img src={{app.logo}} class="main-imgs cursor-pointer" alt={{app.name}} />
            <span>{{app.name}}</span>
            <div [ngClass]="{ 'arrow-right-align': isOpen, 'arrows-hidden': !isOpen }">
              <ng-container>
                <img src="../../../assets/img/side-menu/Up.png" alt="" *ngIf="app.showDropdown" class="up-down-arrow">
                <img src="../../../assets/img/side-menu/Down.png" alt="" *ngIf="!app.showDropdown"
                  class="up-down-arrow">
              </ng-container>
            </div>
          </a>
        </ng-container>
        <ng-container *ngIf="!app.drop_down">
        
            <a href={{getUrl(app)}} class={{generateClassName(app.name,false)}}>
              <img src={{app.logo}} className="main-imgs" alt={{app.name}} width="24" height="24" />
              <span>{{app.name}}</span>
            </a>
        
      
         
        </ng-container>

        <ng-container  *ngIf="app.name != 'Review Cloud'">
        <ng-container *ngIf="app.drop_down && app.showDropdown">
          <div>
            <ng-container *ngFor="let dropDownItem of app.drop_down_data;let i = index">
              <div class={{generateDropDownClassName(dropDownItem.name)}} >
                <a href={{dropDownItem.url}}>
                  
                  <img src={{dropDownItem.logo}} class="dropdown-imgs" alt={{dropDownItem.name}} /> <span class="sidemenu-span"
                   [ngClass]="{'dropdown-span-open': isOpen, 'dropdown-span-close': !isOpen}">
                    {{dropDownItem.name}}
                  </span>
                </a>
              </div>


    
            
              
            </ng-container>
          </div>
        </ng-container>

      </ng-container>


      <ng-container *ngIf="app.name === 'Review Cloud'" >
      <ng-container *ngIf="app.drop_down&&isDropDownOpen">
        <div [ngClass]="{'expanded-bg':isDropDownOpen}" >
          <ng-container *ngFor="let dropDownItem of app.drop_down_data;let i = index">
            <div class={{generateDropDownClassName(dropDownItem.name)}}   >
              <a  (click)="getSubUrl(dropDownItem)" >
                <img src={{dropDownItem.logo}} class="dropdown-imgs"  /> 
                <span
                 [ngClass]="{'dropdown-span-open': isOpen, 'dropdown-span-close': !isOpen}">
                  {{dropDownItem.name}}
                </span>
              </a>
       </div>
        


  
          
            
          </ng-container>
        </div>
      </ng-container>
    </ng-container>



      </div>
    </ng-container>
</div>
</div>
