import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
export class FilterByPipe implements PipeTransform {
  // public transform(value, keys: string, term: string) {

  //   if (!term) { return value; }
  //   return (value || []).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));

  // }

  transform(items: any, filter: any, defaultFilter: boolean): any {
    if (!filter){
      return items;
    }

    if (!Array.isArray(items)){
      return items;
    }

    if (filter && Array.isArray(items)) {
      let filterKeys = Object.keys(filter);

      if (defaultFilter) {
        return items.filter(item =>
            filterKeys.reduce((x, keyName) =>
                (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      }
      else {
        return items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] == "";
          });
        });
      }
    }


    // if (filter.length === 0) { 
    //   items.push("No item found" as any); 
    //   return items; 
    //   } 



  }

  
}

