import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgModule } from '@angular/core';
import { MatTreeModule} from '@angular/material/tree';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { DialogModule } from 'primeng/dialog';
import { ScrollTopModule } from 'primeng/scrolltop';
import {InputMaskModule} from 'primeng/inputmask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {MatMenuModule} from '@angular/material/menu';
import {ChartModule} from 'primeng/chart';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import {MenuModule} from 'primeng/menu';
import {StepsModule} from 'primeng/steps';
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import {CheckboxModule} from 'primeng/checkbox';
import {SidebarModule} from 'primeng/sidebar';
import {ProgressBarModule} from 'primeng/progressbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSelectModule } from 'ngx-select-ex';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import {SliderModule} from 'primeng/slider';
import { RadioButtonModule } from 'primeng/radiobutton';
import {FileUploadModule} from 'primeng/fileupload';
import {BadgeModule} from 'primeng/badge';
import { SplitButtonModule } from 'primeng/splitbutton';
import { RatingModule } from 'primeng/rating';
import {MatRadioModule} from '@angular/material/radio';
import {PanelModule} from 'primeng/panel';
import {MatDividerModule} from '@angular/material/divider';
import {BlockUIModule} from 'primeng/blockui';
@NgModule({
  declarations: [],
  imports: [
    CdkAccordionModule,
    CdkStepperModule,
    CdkTreeModule,
    CommonModule,
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    AlertModule,
    CalendarModule,
    EditorModule,
    DialogModule,
    ScrollTopModule,
    InputMaskModule,
    ConfirmDialogModule,
    MatMenuModule,
    ChartModule,
    DropdownModule,
    InputNumberModule,
    MenuModule,
    StepsModule,
    AccordionModule,
    FieldsetModule,
    CheckboxModule,
    SidebarModule,
    ProgressBarModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxSelectModule,
    OverlayPanelModule,
    SliderModule,
    RadioButtonModule,
    FileUploadModule,
    BadgeModule,
    SplitButtonModule,
    RatingModule,
    BlockUIModule,
    PanelModule,
    MatRadioModule,
    MatDividerModule
    
    

  ],
  exports: [
    CdkAccordionModule,
    CdkStepperModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    AlertModule,
    CalendarModule,
    EditorModule,
    DialogModule,
    ScrollTopModule,
    InputMaskModule,
    ConfirmDialogModule,
    MatMenuModule,
    ChartModule,
    DropdownModule,
    InputNumberModule,
    MenuModule,
    StepsModule,
    AccordionModule,
    FieldsetModule,
    CheckboxModule,
    SidebarModule,
    ProgressBarModule,
    NgSelectModule,
    NgxPaginationModule,
    NgxSelectModule,
    OverlayPanelModule,
    SliderModule,
    RadioButtonModule,
    FileUploadModule,
    BadgeModule,
    SplitButtonModule,
    RatingModule,
    BlockUIModule,
    PanelModule,
    MatRadioModule,
    MatDividerModule
    

  ]
  
})
export class AngularmaterialModule { }
