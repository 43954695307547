import { Action } from '@ngrx/store';

export const LOAD_ERROR_CODES = '[User] Load Error Codes';
export const UPDATE_ERROR_CODES = '[User] Update Error Codes';

export class LoadErrorCodes implements Action {
  readonly type = LOAD_ERROR_CODES;
}

export class UpdateErrorCodes implements Action {
  readonly type = UPDATE_ERROR_CODES;
  constructor(public payload: string[]) { }
}

export type ErrorCodesAction =
  LoadErrorCodes |
  UpdateErrorCodes
  ;
