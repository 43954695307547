import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-signoutpopup',
  templateUrl: './signoutpopup.component.html',
  styleUrls: ['./signoutpopup.component.css']
})
export class SignoutpopupComponent implements OnInit {

user_Data:any;
traineryView:any;
environment=environment;

  constructor(
    private dialogRef: MatDialogRef<SignoutpopupComponent>,
    private route :Router
  ) { }

  ngOnInit(): void {
  this.user_Data=JSON.parse(localStorage.getItem('user_data'));
  
  }

  close(impersonate_acc)
  { 
    if(environment.production=false)
    {
      localStorage.clear();
      this.route.navigate['/login/rc']
    }
else
{


  if(!impersonate_acc)
  {
    this.dialogRef.close();
    localStorage.clear();
    window.location.href = environment.trainery_url+'logout/';
  }
else if(impersonate_acc)
{
this.dialogRef.close();
window.location.href = environment.impersonationUrl

}   
}

  }

  helpPageUrl() {
    

  
     window.open(environment.trainery_help_redirect, '_blank');
    
  }

}




