import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { SignoutpopupComponent } from 'src/app/rvcloud/signoutpopup/signoutpopup.component';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { TrainerydataService } from 'src/app/services/trainerydata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-talentmanagementsection',
  templateUrl: './talentmanagementsection.component.html',
  styleUrls: ['./talentmanagementsection.component.css']
})
export class TalentmanagementsectionComponent implements OnInit {

  opened = false;
  isInitial = true;
  authUser = null;
  expandFlag=false;
  divStyle=47;
  isExandedVisible=false;
  myapps:any;
  trainery_url=environment.trainery_url;
  user_Data:any;
  topMenu:any;
  reviewTopSection:any;
  roles:any;
  environment=environment
  traineryView:any;
  ROUTE:string='';
  organisationImage:string=''
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private traineryData:TrainerydataService,
    private authService: AuthService,
    public dialog: MatDialog,
    private sharedData:SharedService
  ) {


this.ROUTE=this.sharedData.getRoute()

    this.traineryView=localStorage.getItem('traineryView')

    if(this.traineryView=='false')
    {
      let apps:any = this.authService.setTraineryTempData();
      this.myapps = apps;
      this.user_Data = this.traineryData.getTraineryApplicationData('user_data')
      this.organisationImage=this.traineryData.getTraineryApplicationData('organisation')?.logo_url
      let reviewSection = this.traineryData.topMenuAccess('Talent Management')
      this.reviewTopSection=reviewSection;
    
    }
else
{
  this.isExandedVisible = this.authService.getCookie('pinned') == 'True'?true: false;
  this.onExpand();
  this.myapps =   this.traineryData.getTraineryApplicationData('applications')
  this.user_Data = this.traineryData.getTraineryApplicationData('user_data')
  this.roles=this.user_Data.roleName
  let reviewSection = this.traineryData.topMenuAccess('Talent Management')
  this.reviewTopSection=reviewSection;
  this.organisationImage=this.traineryData.getTraineryApplicationData('organisation')?.logo_url


}
    
 
  }


  isAccessable(header:any) {
    /* Level Managers don't have access to upload manager */
    if(this.traineryView=='false')return true;
    if(!header.status) return false;
    else if(!header.sub_menu.length) return false;
    else if(!this.checkAccess(header)) return false;
    else return true;
  }


  checkAccess(header:any) {
    let sub_menu = header.sub_menu;
    let isVisible = false;
    for(let item of sub_menu) {
       let visibleItem = item.role_access.find((access:any) => access.view === true);
       
       if(visibleItem && this.roles) isVisible = true;
    }
    return isVisible;
  }


  getRoles(roleName:string) {
    // let parsedRoles = this.roles?this.roles:[];
    // let roleNames = parsedRoles.map((role:any) => role.name);

    return this.roles.includes(roleName);
  }


  onClickChangeNavigation(topmenu:string)
  {
    switch (topmenu.toUpperCase()) {
      case 'LEARNING PLAN':
        this.router.navigate(['/talentManagmentrc/learningPlan/lp/participants'])

        break;

      case '9 BOX GRID':
        this.router.navigate(['/talentManagmentrc/nineboxgrid/dashboard'])

        break;

        case 'IDP':
          this.router.navigate([`/talentManagmentrc/idp-manager/list`])
  
          break;
    
     




      default:
        break;
    }



  }


   isActiveUrl(topmenu: string): boolean {

    switch (topmenu.toUpperCase()) {
      case '9 BOX GRID':
         return this.router.url.includes(`nineboxgrid`);

         case 'IDP':
          return this.router.url.includes(`idp-manager`);

          case 'LEARNING PLAN':
            return this.router.url.includes(`learningPlan`);
 




      default:
        break;
    }
  }
   

  ngOnInit(): void {
    localStorage.setItem('tab','standard');
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });

  }
  onStatusChange(){
    this.expandFlag=!this.expandFlag;
  }
  toggleSidebar(): void {
    this.opened = !this.opened;
    this.isInitial = false;
  }
  

  SignoutPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass='signout-popup';
    dialogConfig.position = {
      top: '60px',
      right: '20px'
    };

    this.dialog.open(SignoutpopupComponent, dialogConfig);
  }


  onExpand(){
    if(this.isExandedVisible == true){
      this.divStyle = 195;
    }
    else{
      this.divStyle = 46;
    }
  }
  logoutExit(){
    this.authService.logout();
  }


  onActivate(event) {
    window.scroll(0,0);
  }

}
