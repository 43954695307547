import * as fromErrorCodes from '../actions/error-codes.action';

export interface ErrorCodesState {
  errorCodes: string[];
}

export const initialState = {
  errorCodes: []
};

export function reducer(
  state = initialState,
  action: fromErrorCodes.ErrorCodesAction
): ErrorCodesState {
  switch (action.type) {
    case fromErrorCodes.LOAD_ERROR_CODES: {
      return {
        ...state
      };
    }

    case fromErrorCodes.UPDATE_ERROR_CODES: {

      const newErrorCodes: string[] = [...action.payload];

      return {
        ...state,
        errorCodes: [...newErrorCodes]
      };
    }
  }

  return state;
}

export const getErrorCodes = (state: ErrorCodesState) => state.errorCodes;
