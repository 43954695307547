import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})



export class UserroleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }


environment=environment;

canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      var userToken = localStorage.getItem('userToken');
      if (!userToken || localStorage.length ==0) {
        if(environment.production_trainery==true)
        {
          window.location.href = environment.traineryUrlHome;

        }
        else
        {
          this.router.navigate(['/login/rc']);

        }
        
        return false;
      }
      return true;
  }
  
}
