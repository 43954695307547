import { AfterViewInit, Component, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { TrainerydataService } from 'src/app/services/trainerydata.service';
import { TrainerytempviewService } from 'src/app/services/trainerytempview.service';
import { environment } from 'src/environments/environment';

declare var $:any;


@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit,OnChanges {
 
  tempData =[
   
    {
      "name": "Review Cloud",
      "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/reviewcloud/Logo_RC.svg",
      "url": null,
      "drop_down": true,
      "showDropdown": false,
      "drop_down_data": [
        {
          "name": "Review Settings",
          "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/reviewcloud/review conf.svg",
          "url": "reviewrc3/reviewsetting/cyclemain/cycle-list"
        },
        {
          "name": "Performance Management",
          "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/reviewcloud/performance.svg",
          "url": "performancerc3/performancemanagement/performlist/dashboard"
        },
        {
          "name": "Goal Management",
          "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/delegatehr/dhr_dashboard.png",
          "url": "goalManagement"
        },
        {
          "name": "Talent Management",
          "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/reviewcloud/talent-management_v1.svg",
          "url": "talentManagmentrc"
        },
        {
          "name": "Historical Data",
          "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/reviewcloud/report.svg",
          "url": "/history/rc3history/reports/feedbackreport"
        },
        {
          "name": "Reports",
          "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/left_menu_icons/trainerylms/Reports_menu.svg",
          "url": "/rc-reports/report"
        }
        

      ]
    },
 ]



applications:any;
authUser = null;
environment = environment
isOpen!:boolean;
pin!:boolean;
traineryView:any;
isDropDownOpen:boolean=false;

@ViewChild('mep1') mep1!:MatExpansionPanel;
@ViewChild('mep2') mep2!:MatExpansionPanel;
@ViewChild('mep4') mep4!:MatExpansionPanel;


constructor(private authService: AuthService,
  private router: Router,
  private traineryTemp:TrainerytempviewService,
  private traineryData:TrainerydataService
  )  
  {

    
    this.traineryView=localStorage.getItem('traineryView');
    
    if(this.traineryView=='true')
    {
      this.applications=[];
     this.applications= this.traineryData.getTraineryApplicationData('sidebar')
      
    }
    else
    {
      
      this.applications=[];
      this.applications=this.tempData;  
    }





  this.pin = this.authService.getCookie('pinned') == 'True'?true: false;
  if(this.pin)this.toggle();
      
  
}
  ngOnChanges(changes: SimpleChanges): void {

   
  }


ngOnInit(): void {

this.isDropDownOpen=JSON.parse(localStorage.getItem('IsDropDownEnabled'))

}

updateStyles() {
  if ($('.leftmenu-closed').length > 0) {
    $('.count-card').css({
      'aspect-ratio': '2.5',
    });
  } else {
    $('.count-card').css({
      'aspect-ratio': '2.8',
    });
  }
}




setIsOpen(value: boolean): void {
  this.isOpen = value;


  const rightData: HTMLElement | null = document.querySelector('.right-data');
  const leftNav: HTMLElement | null = document.querySelector('.left-nav');

  if (rightData && leftNav) {
    if (this.isOpen) {   
      rightData.style.left = '195px';
      rightData.style.transition = 'left 0.3s';

      leftNav.style.width = '195px';
      leftNav.style.transition = 'width 0.3s';
    } else {
      rightData.style.left = '47px';
      leftNav.style.width = '47px';
    }
  }
}


setPin(value:any){
  this.pin = value;
}
setApplications(applications:any){
  this.applications = [...applications];
}
toggle() {  
  if (this.pin) {
    this.setIsOpen(true);
  } else {
    this.setIsOpen(!this.isOpen);
  } 
  this.updateStyles(); 
};

pinToggle() {
  this.pin = !this.pin;
  this.traineryData.setPushpin(this.pin);
 // this.pin = this.authService.getCookie('pinned') == 'True'?true: false;
};


generateClassName(appName: any, dropDownOpen: any) {

  if(appName=='Review Cloud')
  {
    if(this.isDropDownOpen)
    {
      let baseClass = "collapsed";
      let extraClasses = "";
      if (this.getSubClass(appName, this.isDropDownOpen)) {
        extraClasses = "highlighted";
      } else {
        extraClasses = "";
      }
      let className = !this.isOpen
        ? `${baseClass} ${extraClasses}`
        : `${baseClass} expanded ${extraClasses}`;
      return className;
    }

  }




  let drop;  
  if (dropDownOpen) {
    drop = dropDownOpen;
  } else {
    drop = false;
  }
  let baseClass = "collapsed";
  let extraClasses = "";
  if (this.getSubClass(appName, drop)) {
    extraClasses = "highlighted";
  } else {
    extraClasses = "";
  }
  let className = !this.isOpen
    ? `${baseClass} ${extraClasses}`
    : `${baseClass} expanded ${extraClasses}`;
  return className;
};


getSubClass(appName: any, dropDownOpen: any) {
  const currentUrl = window.location.href;
  
  switch (appName) {
      case "Review Cloud":
      return   (currentUrl.includes("localhost")||currentUrl.includes("testrc.trainery.one"))&&!dropDownOpen
    default:
      return false;
  }
};

generateDropDownClassName(appName: any) {

  let baseClass = this.isOpen ? "dropdown-content-open" : "dropdown-content";
    let extraClasses = "";
  if (this.getDropDownSubClass(appName)) {
    extraClasses = "highlighted";
  } else {
    extraClasses = "";
  }
  let className = `${baseClass} ${extraClasses}`;
  return className;
};

getDropDownSubClass(appName: any) {
  const currentUrl = window.location.href;

  switch (appName) {
   
      case "Review Settings":
        return currentUrl.includes("/reviewrc3");
    case "Performance Management":
      return currentUrl.includes("/performancerc3");
      case "Goal Management":
        return currentUrl.includes("/rc-goal");
        case "Talent Management":
          return currentUrl.includes("/talentManagmentrc");
      case "Historical Data":
        return currentUrl.includes("/history");
          case "Review Builder":
            return currentUrl.includes("/reviewadminsection");
            case "PIP":
              return currentUrl.includes("/rc-pip");
            case "Reports":
              return currentUrl.includes("/rc-report");
                              
    default:
      return false;
  }
};

toggleDropdown = (event: any, index: number,app:any) => {


  event.preventDefault();
  if(app.name=='Review Cloud')
  {
 
    this.isDropDownOpen=!this.isDropDownOpen;
    let data= JSON.stringify(this.isDropDownOpen);
    localStorage.setItem('IsDropDownEnabled',data);
    return this.isDropDownOpen;
  }

  
  const updatedApplications = [...this.applications];
  const updatedApplication = {
    ...updatedApplications[index],
    showDropdown: !updatedApplications[index].showDropdown,
  };
  updatedApplications[index] = updatedApplication;

  
  this.setApplications(updatedApplications);
};

getUrl(app:any) {
  return app.url;
}


getSubUrl(dropDownItem: any) {
  const url = (() => {
    switch (dropDownItem.name) {
      case 'Review Settings':
        return '/reviewrc3/reviewsetting/cyclemain/cycle-list';
      case 'Performance Management':
        return '/performancerc3/performancemanagement/portal';
      case 'Review Builder':
        return 'adminreviewbuildersection/adminreviewbuilder/admincompetency';
      case 'Goal Management':
        return 'rc-goal/goal-setting/dashboard';
      case 'Talent Management':
        return dropDownItem.url.includes('idp')
          ? '/talentManagmentrc/idp-manager/list'
          : 'talentManagmentrc/nineboxgrid';
      case 'IDP':
        return '/talentManagmentrc/idp-manager/list';
      case 'PIP':
        return '/rc-pip/pip-setting/builder/board';
        case 'Reports':
          return '/rc-reports/report';   
      default:
        return '/history/rc3history/reports/feedbackreport';
    }
  })();

  this.router.navigate([url]);
}



}

