import * as fromErrorCodes from './error-codes.reducer';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

export interface UserState {
  errorCodes: fromErrorCodes.ErrorCodesState;
}

export const reducers: ActionReducerMap<UserState> = {
  errorCodes: fromErrorCodes.reducer,
};

export const userState = createFeatureSelector<UserState>('user');//superAdminState

// Master Questions
export const getErrorCodesState = createSelector(userState, (state: UserState) => state.errorCodes);

export const getAllErrorCodes = createSelector(getErrorCodesState, (fromErrorCodes.getErrorCodes));
