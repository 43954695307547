import { Admin } from './admin.model';
export class AuthUser {
  constructor(
    public role: string,
    // tslint:disable-next-line:variable-name
    private _token: string,
    // tslint:disable-next-line:variable-name
    private _tokenExpirationDate: Date,
    private _payload: Admin,
  ) {
    // setInterval(()=> { this.expireCheck(_tokenExpirationDate) },  1000);
   }
  //  expireCheck(date2){
  //   const cur:any=new Date();
  //   const dateDiff:any=date2 - cur;
  //  }
  get token() {
    // if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
    //   return null;
    // }
    return this._token;
  }

  get userDetails() {
    // if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
    //   return null;
    // }
   return this._payload;
  }
}
