import { ErrorHandlerService } from '../services/error-handler.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthUser } from '../models/auth-user';
import { Admin } from 'src/app/models/admin.model';
import { environment } from '../../environments/environment';
import { DatePipe, NgIf } from '@angular/common'




@Injectable({ providedIn: 'root' })
export class AuthService {

  TraineryAppData:any=[{"id":6,
  "app_id":"APP000006","name":"Review Cloud",
  "url":"https://rc-uat-hotfix.trainery.one/login",
  "logo":"https://testapps-cdn.trainery.one/application/reviewCloud21112023060222.png"
}]


  baseUrl = environment.baseUrl;
  traineryUrl=environment.traineryUrl;

  redirectUrl = '';
  //usersUrl: string;
  usersUrl = this.baseUrl + '/authenticate';
  forgotPasswordURL=this.baseUrl + '/forgotpassword';
  // updatePasswordURL=this.baseUrl + '/customPwd';
  updatePasswordURL=this.baseUrl + '/updatepassword';
  tempLoginCheckURL=this.baseUrl + '/maillogin';
  generalSettingsURL=this.baseUrl + '/getmodulesettings';
  signupURL=this.baseUrl + '/admin/createorg';
  signupEditURL=this.baseUrl + '/admin/updateorg ';
  openLoginURL=this.baseUrl + '/openauthenticate';
  rcAdminLoginURL=this.baseUrl + '/adminauth';
  checkEmail=this.baseUrl + '/checkuserrole';
  getAllContactDetailsURL = this.baseUrl + '/admin/listorgusers';
  authUserTraineryApi=this.traineryUrl+'api/auth-user';
  ssologinApi=this.baseUrl+'/ssologin';
  ssologinApis=this.baseUrl+'/ssologin';
traineryApi=environment.traineryUrl + '/api/auth-user/'


  data=null;
  expireDate =Date;
  diff=null;
  checkFlag=false;
  role='';
  user = new BehaviorSubject<AuthUser>(null);
  superadmin = new BehaviorSubject<AuthUser>(null);
 //  added for local only
  private tokenExpirationTimer: any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private errorHandler: ErrorHandlerService,
    public datepipe: DatePipe,
   ) {
  }
  getUserData(){
    const userData: {
      role: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));
    if(userData){
      return userData;
    }
    else{
      return null;
    }
  }

  checkuserEmail(username): Observable<any> {

  return this.http
  .post<any>(
    this.checkEmail, { username }
  )
  .pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 0) {
       this.errorHandler.handleError(error)
       return throwError(error);
      }
      else
      {
        this.errorHandler.handleError(error)
        return throwError(error);
      }
     // Rethrow the error to propagate it
    })
  )

  }
  getSuperAdminData(){
    const userData: {
      role: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));
    if(userData){
      return userData;
    }
    else{
      return null;
    }
  }
  generalSettings(id): Observable<any> {
    return this.http
      .post<any>(
        this.generalSettingsURL, { id }
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err))
      );
  }
  //signup
  signupAdmin(fname,lname,employee_id,email,password,job_title,company_name,phone): Observable<any> {
  return this.http
    .post<any>(
      this.signupURL, {fname,lname,employee_id,email,password,job_title,company_name,phone}
    )
    .pipe(
      catchError((err) => this.errorHandler.handleError(err))
    );
  }
   //signup
   signupSAdmin(data): Observable<any> {
    return this.http
      .post<any>(
        this.signupURL, {...data}
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err))
      );
    }

       //signup
   signupSAdminUpdate(data): Observable<any> {
    return this.http
      .post<any>(
        this.signupEditURL, {...data}
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err))
      );
    }

  
  
  login(username: string, password: string, role: string): Observable<any> {
    return this.http
      .post<any>(
        this.usersUrl,
        {
          username,
          password,
          role
        }
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err)),
        tap(resData => {
  
          if(resData.message != "success")
          {
           this.errorHandler.showErrorMessageDialog('ERROR',resData.message);
          }
          this.handleAuthentication(
            role,
            resData.token,
            resData.tokenExpirationDate,
            resData.payload
          );
        })
      );
  }
  rcAdminLogin(username: string, password: string, role: string): Observable<any> {
    return this.http
      .post<any>(
        this.rcAdminLoginURL, {username,
          password,
          role}
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err)),
        tap(resData => {
          if(resData.message != "success")
          {
            this.errorHandler.showErrorMessageDialog('ERROR',resData.message);
          }
          this.handleAuthenticationSuperAdmin(
            role,
            resData.token,
            resData.tokenExpirationDate,
            resData.payload
          );
        })
      );
  }
  loggedIn(){
    return !!localStorage.getItem('userToken');
  }
  getRole(){
    return localStorage.getItem('userType');
  }
  logout(): void {
    const userData: {
      role: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));
    this.user.next(null);
    localStorage.removeItem('userToken');
    localStorage.removeItem('userType');
    localStorage.removeItem('userData');
    if(environment.production_trainery==true)
    {
      window.location.href = environment.traineryUrlHome;

    }
    else
    {
      this.router.navigate(['/login/rc']);

    }
        if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }
  logoutAdmin(): void {
    const userData: {
      role: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));
    this.user.next(null);
    localStorage.removeItem('superAdminToken');
    localStorage.removeItem('superAdminType');
    localStorage.removeItem('superAdminData');
    if(environment.production_trainery==true)
    {
      window.location.href = environment.traineryUrlHome;

    }
    else
    {
      this.router.navigate(['/login/rc']);

    }
        if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number): void {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }
  curDate(){
    const cur=new Date();
    return cur;
  }
  expireCheck(date2){
    const date1=new Date();
    this.diff=date2.getTime() - date1.getTime();
   if(this.diff <= 0){
    this.checkFlag=true;
    this.logout();
  
   
    //  this.router.navigate(['/auth/login']);
   window.location.reload();
   }
   else{
    this.diff=null;
   }
  }

  // tslint:disable-next-line: typedef
  private handleAuthentication(
    role: string,
    token: string,
    expiresIn: Date,
    payload: Admin
  ) {
   const curDate:any = new Date(); 
  const date2:any=new Date(expiresIn);
    const expirationDate =date2;
    const user = new AuthUser(role, token, expirationDate, payload);
    this.user.next(user);
    const date1=new Date();
    this.diff=date2.getTime() - date1.getTime();
     this.autoLogout(this.diff);
     localStorage.clear();
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('userToken', token);
    localStorage.setItem('userType', payload['user_type']);
  }
  handleAuthenticationTemp
  (
    role: string,
    token: string,
    expiresIn: Date,
    payload: Admin
  ) {
   const curDate:any = new Date(); 
  const date2:any=new Date(expiresIn);
    const expirationDate =date2;
    const user = new AuthUser(role, token, expirationDate, payload);
    this.user.next(user);
    const date1=new Date();
    this.diff=date2.getTime() - date1.getTime();
     this.autoLogout(this.diff);
     localStorage.clear();
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('userToken', token);
    localStorage.setItem('userType', payload['user_type']);
  }
  handleAuthenticationSuperAdmin(
    role: string,
    token: string,
    expiresIn: Date,
    payload: Admin
  ) {
   const curDate:any = new Date(); 
  const date2:any=new Date(expiresIn);
    const expirationDate =date2;
    const superadmin = new AuthUser(role, token, expirationDate, payload);
    this.superadmin.next(superadmin);
    const date1=new Date();
    this.diff=date2.getTime() - date1.getTime();
     this.autoLogout(this.diff);
     localStorage.clear();
    localStorage.setItem('userData', JSON.stringify(superadmin));
    localStorage.setItem('userToken', token);
    localStorage.setItem('superAdminType', payload['user_type']);
  }
  forgotPassword(email): Observable<any> {
  return this.http
    .post<any>(
      this.forgotPasswordURL, {email}
    )
    .pipe(
      catchError((err) => this.errorHandler.handleError(err))
    );
}
tempLoginCheck(authcode,authemail): Observable<any> {
  let params = new HttpParams();
  params = params.append('authcode', authcode);
  params = params.append('authemail', authemail);

  return this.http.get(this.tempLoginCheckURL, { params: params })  .pipe(
    catchError((err) => this.errorHandler.handleError(err)),
    tap(resData => {
      if(resData.message != "success")
      {
        this.errorHandler.showErrorMessageDialog('ERROR',resData.message);
        // this.logout();
      }
      this.role=resData['payload'].role;
      this.handleAuthenticationTemp(
        this.role,
        resData.token,
        resData.tokenExpirationDate,
        resData.payload
      );
    })
  );

}
updatePassword(email,password): Observable<any> {
return this.http
  .post<any>(
    this.updatePasswordURL, {email,password}
  )
  .pipe(
    catchError((err) => this.errorHandler.handleError(err))
  );
}
openLogin(authcode,authemail): Observable<any> {
  let params = new HttpParams();
  params = params.append('authcode', authcode);
  params = params.append('authemail', authemail);

  return this.http.get(this.openLoginURL, { params: params })  .pipe(
    catchError((err) => this.errorHandler.handleError(err)),
    tap(resData => {
      if(resData.message != "success")
      {
        this.errorHandler.showErrorMessageDialog('ERROR',resData.message);
      }
      this.role=resData['payload'].role;
      this.handleAuthentication(
        this.role,
        resData.token,
        resData.tokenExpirationDate,
        resData.payload
      );
    })
  );
}
  getAllContactDetails(org_id) {
    return this.http
      .post<any>(
        this.getAllContactDetailsURL, {org_id}
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err))
      );
  }

  getSingleCompany(org_id) {
    return this.http
      .post<any>(
        this.getAllContactDetailsURL, {org_id}
      )
      .pipe(
        catchError((err) => this.errorHandler.handleError(err))
      );
  }
  

  sso_auth_user() {
    return this.http.get<any>( this.traineryApi, {
      withCredentials: true
    })
    .pipe(
      catchError((err) => this.errorHandler.handleError(err)),
    );
  }

  ssoLoginAuth(formsValue: any) {
      return this.http
        .post<any>(
          this.ssologinApi, {...formsValue}
        )
        .pipe(
          catchError((err) => this.errorHandler.handleError(err)),
          tap(resData => {
    
            // if(resData.message != "success")
            // {
            //  this.errorHandler.showErrorMessageDialog('ERROR',resData.message);
            // }
            this.handleAuthentication(
              'admin',
              resData.token,
              resData.tokenExpirationDate,
              resData.payload
            );
          })
        );
    }


    ssoLoginAuths(username) {
      return this.http
        .post<any>(
          this.ssologinApis, {username}
        )
        .pipe(
          catchError((err) => this.errorHandler.handleError(err)),
          tap(resData => {
    
            // if(resData.message != "success")
            // {
            //  this.errorHandler.showErrorMessageDialog('ERROR',resData.message);
            // }
            this.handleAuthentication(
              'admin',
              resData.token,
              resData.tokenExpirationDate,
              resData.payload
            );
          })
        );
    }


   getRolesList()
   {

    let authData = JSON.parse(localStorage.getItem('userData'));    
    let role =  authData._payload.userRole;

    if(role=='hr')
      {
        let role=[
          {"id":"employee","name":"Employee"},
          {"id":"manager","name":"Manager"},
          {"id":"hr","name":"HR"}
        ]  

          return role;
      }

      else if(role=='manager')
      {
        let role=[
          {"id":"employee","name":"Employee"},
          {"id":"manager","name":"Manager"},
        ]  

          return role;

      }

      else
      {

        let role=[
          {"id":"employee","name":"Employee"},
        ]  
          return role;
      }







   } 

getCookie(name)
{
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
   const [cookieName,cookieValue]=cookies[i].split("=");
   if(cookieName==name)
   {
 
    return cookieValue;
   }
    
  }
  return null;
}
  


setTraineryTempData():void
{
  return this.TraineryAppData;
}


}
