<div class="page-wrap">
    <div class="page-not-found">
      <img src="https://res.cloudinary.com/razeshzone/image/upload/v1588316204/house-key_yrqvxv.svg" class="img-key" alt="">
      <h1 class="text-xl">
        <span>4</span>
        <span>0</span>
        <span class="broken">3</span>
      </h1>
      <h4 class="text-md mb-4">Access Denied !</h4>
      <h4 class="text-sm text-sm-btm">You don’t have access to this area of application. You can go back to <a class="cursor-pointer" (click)="goBack()">previous page</a></h4>
    </div>
  </div>