import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable, Subject } from 'rxjs';
import * as fromStore from '../shared/store';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  errors: string;
  errorMessages: any;
  errorRes:any;
errorStatus:any;
  errorCodes$: Observable<any>;
  constructor(private toastr: ToastrService, 
    private dialog:MatDialog,
    private store: Store<fromStore.UserState>) {
    this.errorCodes$ = this.store.select<any>(fromStore.getAllErrorCodes);
  }

  handleError(errorRes: HttpErrorResponse): Observable<never> {
  
    
  this.errorRes=errorRes.error;
this.errorStatus=errorRes.status
      
let errors=errorRes.status;
    this.errorMessages=errorRes.error.message;
    
this.errors=errorRes.error.message;

    if(this.errorMessages==''||this.errorMessages==null||errors==0)
    {      


    this.errorMessages='Something went wrong please try again!';

    }
  

    const error = errorRes.error;
    


   return throwError(this.errorMessages);
  }


  //ERROR DIALOG BOX (SERVER ERROR MESSAGE)
  showErrorDialog()
  {
    if(this.errorStatus!==0&&this.errorStatus!=401)
    {
            
      Swal.fire(this.errorRes.head?this.errorRes.head:'Error',this.errorRes.message?this.errorRes.message:'Something Went Wrong','error')


    }
    

  }

  showWarningDialog(head:string,message:string):void{

    Swal.fire(head,message,'warning')

  }


  //INFO DIALOG BOX 
  showInfoDialog(head:string,message:string): void  {


    Swal.fire(head,message,'info')




  }


   //INFO DIALOG BOX 
   showErrorMessageDialog(head:string,message:string): void  {


    Swal.fire(head,message,'error')



  


  }





  showSuccessMessage(message): void {

    Swal.fire({
      icon: "success",
      title: 'Success',
      showConfirmButton: false,
      timer: 1500,
      html:message
    });


  }


  showSuccessMessageToaster(message):void{
        this.toastr.success(message, 'Success', {
      closeButton: true,
      progressBar: true,
      enableHtml: true,

      
    });
  }


  showInfoMessage(message): void {
    this.toastr.info(message, 'Information', {
      closeButton: true,
      progressBar: true,
      enableHtml: true,

      
    });
  }

  showError(datas): void {
   
    this.errors = '';
    if (datas) {
      datas.forEach(element => {
        this.errors += '- ' + element + '<br/>';
      });
      this.toastr.error(this.errors, 'Error', {
        closeButton: true,
        progressBar: true,
        enableHtml: true,
  
        
      });
    }
  }

  showErrorMessage(message): void {

    Swal.fire('Error',message,'error')
   
    // this.errors = '';
    // this.toastr.error(message, 'Error', {
    //   closeButton: true,
    //   progressBar: true,
    //   enableHtml: true,
      
      
    // });
  }
  showLoginError(datas): void {
    this.toastr.info(datas, '', {
      closeButton: true,
      progressBar: true,
      enableHtml: true,

    });
  }
}
