import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-rclogout',
  templateUrl: './rclogout.component.html',
  styleUrls: ['./rclogout.component.css']
})
export class RclogoutComponent implements  OnDestroy {

  constructor() { }

  ngOnDestroy(): void {
    localStorage.clear(); 
  }


}
