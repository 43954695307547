//inDevelopmentReviewlayout - Design Layout Changes in The Self Review, Manager Review, Hr Review Components (03-05-2023)

export const environment = {
  production: true,
  baseUrl:'https://rc-api.trainery.one',
  traineryUrl:'https://apps-api.trainery.one',
  trainery_url:'https://apps.trainery.one/',
  trainery_redirect_profile:'https://coreapps.trainery.one/home/profile',
  trainery_change_password:'https://apps.trainery.one/logged-in-user-reset-password',
  trainery_help_redirect:'https://help.trainery.one/hcm/performance/how-to-login-2',
  production_trainery:true,
  impersonationUrl:'https://apps.trainery.one/traineryone/client/impersonate/switchback',
  traineryUrlHome:'https://apps.trainery.one/home',
  staging:false

};
