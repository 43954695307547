
<!-- LOGIN PAGE STARTS HERE -->
<ng-container *ngIf="viewTrainery">
<app-spinner></app-spinner>
</ng-container>


<ng-container *ngIf="!viewTrainery">
<div class="row">
    <div class="col-sm-6 login-left">
    
      <!-- LEFT SIDE STARTS HERE -->

      <div class="left-text">
        <div>
          <img class="logospaces" src="../../../assets/images/reviewCloud.png" />
        </div>
        <span style="color: #ffffff;" 
          >Simple Performance Management that
          <span style="color: #27b7ed !important"> Works </span></span
        >
      </div>
      <div>
        <p  class="login-para">
          ReviewCloud is part of Trainery™, a Human Capital Management Solutions
          provider. Collectively, we provide the tools and technology you need to
          create a thriving workplace for your most valuable asset<span>—People.</span>
        </p>
      </div>
      <div class="login-link-div">
          <a href="https://reviewcloud.co/"  class="login-link">
          Learn More
          </a>
        </div>
    </div>
  

    <!-- RIGHT SIDE STARTS HERE -->

    <div class="col-sm-6 login-right" *ngIf="this.noData==false">
      <div class="form-box">
        <div>
          <div class="signinto">Welcome</div>
          <div class="signintop">Sign into your account</div>
        </div>

        <!-- LOGIN FORM START HERE -->

        <form
          [formGroup]="loginForm_check"
          style="padding: 7% 0% 34% 0%; margin-top: 5%"
          (ngSubmit)="onSumbit_check()"
        >
          <input type="hidden" name="_token" value="${_token}" />
         
          <div class="input-box">
            <span class="auth-label">Email</span>
            <input type="text" class="login_input1" formControlName="username"
            name='username' id="email" placeholder="Email " >
            <small style="color:  #dc3545;"
            *ngIf="
            (this.loginForm_check.get('username')?.touched ||
              this.loginForm_check.get('username')?.dirty) &&
            this.loginForm_check.get('username')?.errors
          "
           >
            Email is required.
        </small>
          </div>
          <div class="input-box">
            <span class="auth-label">Password</span>
            <input
              type="password"
              class="login_input1"
              formControlName="password"
              name="password"
              id="password"
              placeholder="Password"
            />
            <small
              style="color: #dc3545"
              *ngIf="
                (this.loginForm_check.get('password')?.touched ||
                  this.loginForm_check.get('password')?.dirty) &&
                this.loginForm_check.get('password')?.errors
              "
            >
              Password required.
            </small>
          </div>
          <div>
            <br />
            <button type="submit" class="btn btn-next" id="signin">
              Sign in
            </button>
            <br />
            <a
              href="forgotpassword"
              [routerLink]="['/auth', 'forgot-password',{id:this.loginForm_check.value.username}]"
              class="frgt-pswd"
              >Forgot Your Password?</a
            >
          </div>
        
         

        </form>
      </div>
    </div>


    <!-- LOADING SPINNER STARTS -->
  
    <div class="col-sm-6 login-right" *ngIf="this.noData==true">
  
      <div>
          <mat-spinner color="white"></mat-spinner>
        </div>
      </div>
  
  </div>

</ng-container>


