import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainerytempviewService {

  constructor() { }

  

   access= [
    {
        "name": "Organization",
        "status": true,
        "side_menu": [
            {
                "name": "",
                "status": true,
                "top_menu": [
                    {
                        "name": "PROFILE",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "PROFILES",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5637,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "SUBSCRIPTION",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5638,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "CONTACTS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5641,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "STRUCTURE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5639,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "LOCATIONS",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5640,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "PROFILE",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5659,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "SETTINGS",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "LOGO",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5642,
                                        "role": "RC Admin",
                                        "view": false,
                                        "update": false
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "name": "Employees",
        "status": true,
        "side_menu": [
            {
                "name": "",
                "status": true,
                "top_menu": [
                    {
                        "name": "SUMMARY",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "EMPLOYEES SUMMARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5643,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "UPLOAD MANAGER",
                        "status": true,
                        "sub_menu": [
                            {
                                "name": "USERS UPLOAD HISTORY SUMMARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5644,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            },
                            {
                                "name": "UPLOAD DATA SUMMARY",
                                "status": true,
                                "role_access": [
                                    {
                                        "id": 5645,
                                        "role": "RC Admin",
                                        "view": true,
                                        "update": true
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        "name": "Review Cloud",
        "status": true,
            "side_menu": [
              {
                "name": "Review Settings",
                "status": true,
                "top_menu": [
                  {
                    "name": "Cycle Management",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": " ",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5635,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Templates",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": " ",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5646,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Review Builder",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Competency",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5649,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Elements",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5648,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Competency Library",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5647,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Element Library",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5647,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Performance Management",
                "status": true,
                "top_menu": [
                  {
                    "name": "My Portal",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                    ]
                  },
                  {
                    "name": "Analytics",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Analytics",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Performance",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "My Portal",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5650,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Reviews",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Review",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Check In",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5653,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "360 Feedback",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5654,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Learning Plan",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5654,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Pip",
                    "status": true,
                    "sub_menu": []
                  },
                  {
                    "name": "Reports",
                    "status": true,
                    "sub_menu": []
                  }
                ]
              },
              {
                "name": "Goal Management",
                "status": true,
                "top_menu": [
                  {
                    "name": "Goalboard",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "My Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Team Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5650,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Hr Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5650,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Goal Manager",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Goal Tracker",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Goal Cycles",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Goal Builder",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Template",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Goal",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5653,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Talent Management",
                "status": true,
                "top_menu": [
                  {
                    "name": "Learning Plan",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Participants/Employees",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Courses",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5650,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "9 Box Grid",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Gridboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      // {
                      //   "name": "Grid Assignment",
                      //   "status": true,
                      //   "role_access": [
                      //     {
                      //       "id": 5651,
                      //       "role": "Rc Admin",
                      //       "view": true,
                      //       "update": true
                      //     }
                      //   ]
                      // },
                      {
                        "name": "Grid Cycle",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Box Settings",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Idp",
                    "status": true,
                    "sub_menu": []
                  }
                ]
              },
              {
                "name": "Pip",
                "status": true,
                "top_menu": [
                  {
                    "name": "Dashboard",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "My Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5651,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Team Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5650,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Hr Dashboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5650,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Manager",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "My Goal",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Team Goals",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5653,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "name": "Builder",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Flowboard",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Cycle",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Template",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5652,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Goal",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5653,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Category",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5654,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Historical Data",
                "status": true,
                "top_menu": [
                  {
                    "name": "Historic Data",
                    "status": true,
                    "sub_menu": [
                      {
                        "name": "Feedback",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5655,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      },
                      {
                        "name": "Goal",
                        "status": true,
                        "role_access": [
                          {
                            "id": 5655,
                            "role": "Rc Admin",
                            "view": true,
                            "update": true
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Reports",
                "status": true,
                "top_menu": [
                  {
                    "name": "Reports",
                    "status": true,
                    "sub_menu": [
                    ]
                  },
                  {
                    "name": "Custom Report",
                    "status": true,
                    "sub_menu": [
                    ]
                  }
            ]
              }
   ]}
]
data={
  "user_id": 3162,
  "email": "lijithca@yopmail.com",
  "role": 26,
  "full_name": "lijith CL",
  "profile_pic": null,
  "first_name": "lijith",
  "impersonate_acc":false,
  "fiscalEndMonth":6
}


application=[
    {
        "id": 1,
        "app_id": "APP000001",
        "name": "Trak Learning",
        "key_name": "TL",
        "url": "https://testtrak.trainery.one/trak-learning-login/",
        "logo": "https://testapps-cdn.trainery.one/application/TrakLearning_Logo-Small-Size-Usage-2024-Color25092024123107.png"
    },
    {
        "id": 2,
        "app_id": "APP000002",
        "name": "Trak Coaching",
        "key_name": "TC",
        "url": "https://testtrak.trainery.one/trak-coaching-login/",
        "logo": "https://testapps-cdn.trainery.one/application/Coaching-latest14052024054049.jpg"
    },
    {
        "id": 5,
        "app_id": "APP000005",
        "name": "TraineryLMS",
        "key_name": "LMS",
        "url": "https://testreports.trainery.one/overview-dashboard/3214",
        "logo": "https://testapps-cdn.trainery.one/CommonNavMenus/lms.png"
    },
    {
        "id": 6,
        "app_id": "APP000006",
        "name": "Review Cloud",
        "key_name": "RC",
        "url": "http://testrc.trainery.one/login/rc",
        "logo": "https://testapps-cdn.trainery.one/application/ReviewCloud_Logo_v2.png"
    }
]


organisation={
  "id": 285,
  "client_id": "114546",
  "name": "UBT",
  "enrollment_type": "Organization",
  "send_welcome_email": true,
  "logo": "organization/pexels-steve-126996810032025040713.jpg",
  "logo_url": null,
  "fiscal_end": 6,
  "email_and_user_id_login": false,
  "client_type": null,
  "location_access": false,
  "ilt_enabled": false
}


getOrganisationAccess(){
  localStorage.setItem('organisation', JSON.stringify(this.organisation));

}

getApplicationAccess()
{
localStorage.setItem('applications', JSON.stringify(this.application));

}




  getTraineryAccess()
  {
    localStorage.setItem('access',JSON.stringify(this.access))
  }

getUserData()
{
  localStorage.setItem('user_data',JSON.stringify(this.data))

}





}
