import { AuthService } from '../auth.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpHeaders,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService,private router: Router) { }

  environment=environment;

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    //const protectedRootBase = ['admin'];
   
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    
    let userToken = null;
    let token =null;
    try {
      userToken = localStorage.getItem('userToken');
      // token = localStorage.getItem('token');
    } catch (error) {
      console.error('No access token found. Please logout and try again.')
    }
    if(req.url.includes(environment.baseUrl))
    {
      if (userToken) {
            
        const modifiedReq = req.clone({
          headers:
            req.headers
               .delete('Authorization')
               .delete('Token')
               .append('Authorization', 'Bearer ' + userToken)
              //  .append('Token',token)
              //  .append('Access-Control-Request-Method', 'POST')
              //  .append('Content-Type', 'application/json')
              // .append('Access-Control-Request-Headers', 'Content-Type,Authorization')
        });
        // return next.handle(modifiedReq);
        return next.handle(modifiedReq).pipe( tap(() => {},
        (err: any) => {
        if (err instanceof HttpErrorResponse) {
          
  
          if(environment.production==true)
          {
            if (err.status == 403) {
              window.location.href=environment.traineryUrlHome
              return;
              }
      
              if (err.status == 401) {
              window.location.href=environment.traineryUrlHome
              return;
              }
  
          }
          else
          {
            if (err.status == 403) {
              this.router.navigate(['/login/rc'])
              return;
              }
      
              if (err.status == 401) {
              this.router.navigate(['/login/rc'])
              return;
              }
  
  
          }
        
  
          if (err.status == 0) {
            this.router.navigate(['/error']);
            return;
          }

          if (err.status == 5001) {
            this.router.navigate(['/error']);
            return;
          }
          
         
        }
      }));
    
    
      }
      else{
        const modifiedReq = req.clone({
          headers:
            req.headers
              .append('Content-Type', 'application/json')
        });
          return next.handle(modifiedReq).pipe( tap(() => {},
          (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 403) {
              this.router.navigate(['/login/rc']);
            
            return;
            }
          
          }
        }));  
      }
    }
    
      
 
    
    return next.handle(req);

    // if (req.url.split('/')[2]) {
    //   if (protectedRootBase.indexOf(req.url.split('/')[2]) > -1) {
    //     if (this.authService.user.value) {
    //       const modifiedReq = req.clone({
    //         headers:
    //           req.headers.delete('Authorization')
    //             .append('Authorization', environment.APIAccessKey)
    //             .append('Authorization', 'Bearer ' + this.authService.user.value.token)
    //       });
    //       return next.handle(modifiedReq);
    //     }
    //   }
    // }
    // return next.handle(req);
  }
}
