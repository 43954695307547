import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrls: ['./errorpage.component.css']
})
export class ErrorpageComponent implements OnInit {

  environment=environment;

  constructor(
    private router : Router
  ) { }

  ngOnInit(): void {
  }


  onClickRedirectToLoginPage()
  {
    localStorage.clear();
    window.location.href=environment.traineryUrlHome
 
  }

}
